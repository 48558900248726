import * as React from "react";
import BoxContainer from "../BoxContainer";
import Text from "../Text";
import { Link } from "gatsby";
import textResolver from "../../core/utils/text-resolver";

const TranslationServiceGrid = ({ data }) => {
  const { dataType = 0 } = data;
  return (
    <BoxContainer
      background="bg-white"
      className="items-center py-16 px-5 2xl:px-0"
    >
      <div
        className="text-blue xs:text-center sm:text-left max-w-6xl xl:mx-auto"
        id="link-orange"
      >
        <Text
          as="h2"
          highlightedText={
            dataType === 1
              ? data.highlightWords
              : textResolver(data, "highlightWords")
          }
          highlightedColor={data.highlightedColor}
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        <Text
          as="div"
          className="text-sm font-regular font-opensans text-blue xs:text-center sm:text-left pb-6"
          id="link-orange"
        >
          {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
        </Text>
      </div>
      <div className="max-w-sm md:max-w-none xs:mx-auto md:mx-0">
        <ul className="list-none grid xs:grid-cols-1 md:grid-cols-3 gap-1 justify-center items-start w-full md:w-10/12 max-w-6xl mx-auto 2xl:w-full 2xl:gap-x-4">
          {data?.clients.length > 0
            ? data.clients.map((item, i) => (
                <>
                  {item.link ? (
                    <li key={i} className="flex gap-3 items-center">
                      <img
                        src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                        alt="check"
                        className="w-6 h-5"
                      />
                      <Link
                        to={
                          dataType === 1
                            ? item.link
                            : textResolver(item, "link")
                        }
                        className="text-sm text-blue font-opensans cursor-pointer hover:text-yellowHover transition-colors"
                      >
                        {dataType === 1
                          ? item.title
                          : textResolver(item, "title")}
                      </Link>
                    </li>
                  ) : (
                    <li
                      key={i}
                      className="text-sm text-blue font-opensans flex gap-3 items-center"
                    >
                      <img
                        src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                        alt="check"
                        className="w-6 h-5"
                      />
                      <span>
                        {dataType === 1
                          ? item.title
                          : textResolver(item, "title")}
                      </span>
                    </li>
                  )}
                </>
              ))
            : ""}
        </ul>
      </div>
    </BoxContainer>
  );
};

export default TranslationServiceGrid;
