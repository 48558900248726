import React from "react";
import Seo from "../seo";
import Banner from "../../LegalTranscription/Home/Banner";
import TextRow from "../../LegalTranscription/Pillar/TextRow";
import MainUsp from "../../LegalTranscription/Home/MainUsp";
import TwoColGridWithOrder from "../../LegalTranscription/Pillar/TwoColGridWithOrder";
import GlobalCta from "../../components/GlobalCta";
import Onside from "../../LegalTranscription/Home/Onside";
import FAQSimple from "../../components/FAQSimple";
import CtaWithForm from "../../LegalTranscription/Home/CtaWithForm";
import Footer from "../../LegalTranscription/Home/Footer";
import TranslationServiceGrid from "../../components/TranslationServiceGrid";

import {
  legalTranscriptionHeaderdata,
  bannerSealData,
  contactusPopupData,
  thankYouPopUpData,
  legalTranscriptionFooterData,
} from "../../LegalTranscription/utils/sample-data";

function ServicePages(props) {
  const pageData = props.pageContext.data;
  const { data: bannerData } = pageData.widgets[0];
  bannerData.dataType = 1;

  return (
    <>
      <Seo
        title={pageData.meta.meta_title}
        description={pageData.meta.meta_description}
        keywords={pageData.meta.meta_keywords}
        slug={pageData.slug}
      />
      {bannerData && (
        <Banner
          data={bannerData}
          headerData={legalTranscriptionHeaderdata}
          sealData={bannerSealData}
          contactusPopUpData={contactusPopupData}
          thankYouPopUpData={thankYouPopUpData}
        />
      )}
      {pageData.widgets &&
        pageData.widgets.map(({ widget, data }, i) => {
          data.dataType = 1;
          if (widget === "block_three_column_list" && i == 1) {
            return (
              <div key={i}>
                <TextRow data={data} />
              </div>
            );
          } else if (widget === "block_certification_samples" && i == 2) {
            return (
              <div key={i}>
                <MainUsp data={data} />
              </div>
            );
          } else if (widget === "block_why_choose_us") {
            return (
              <div key={i}>
                <TwoColGridWithOrder data={data} />
              </div>
            );
          } else if (widget === "block_contact_us") {
            return (
              <div key={i}>
                <GlobalCta
                  data={data}
                  background="bg-gradient-to-r from-[#EB478C] to-[#EC9D5B] w-full mx-auto"
                  className="flex xs:flex-col lg:flex-row items-center justify-between xs:gap-7 lg:gap-28 max-w-6xl mx-auto px-5 2xl:px-0 py-16"
                />
              </div>
            );
          } else if (widget === "block_certification_samples" && i == 5) {
            return (
              <div key={i}>
                <Onside data={data} />
              </div>
            );
          } else if (widget === "block_faq") {
            return (
              <div key={i}>
                <FAQSimple data={data} />
              </div>
            );
          } else if (
            widget === "block_three_column_list" &&
            (i == 7 || i == 6)
          ) {
            return (
              <div key={i}>
                <TranslationServiceGrid data={data} />
              </div>
            );
          } else if (widget === "block_service_contact_us") {
            return (
              <div key={i}>
                <CtaWithForm
                  data={data}
                  background="bg-blue"
                  btnStyle="bg-yellow hover:bg-lightYellow"
                />
              </div>
            );
          }
          return false;
        })}
      <Footer data={legalTranscriptionFooterData} />
    </>
  );
}
export default ServicePages;
