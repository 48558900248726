import React from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import OptimizedImage from "../../hooks/OptimizedImage";

function TwoColGridWithOrder({
  data,
  background = "bg-gradient-to-t from-gradient1  to-white",
  py = "py-14 lg:py-24",
  maxWidth = "max-w-6xl",
  maxWidthTitle = "max-w-4xl",
  order = "textFirst",
  imageWidth = "w-11/12", // 91.66%, 83%
}) {
  const { dataType = 0 } = data;

  return (
    <BoxContainer background={background} className={`px-5 2xl:px-0 ${py}`}>
      <div className={`w-full ${maxWidth} mx-auto`} id="link-orange">
        <div className={`w-full ${maxWidthTitle} mx-auto`}>
          <Text
            as="h2"
            highlightedText={
              dataType === 1
                ? data.highlightWords
                : textResolver(data, "highlightWords")
            }
            highlightedColor={data.highlightedColor}
            className={`text-5xl leading-[70px] text-blue text-center font-primary font-bold ${
              data.description ? "mb-10" : "mb-20"
            }`}
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
          {data.description ? (
            <Text
              as="div"
              className="text-lg leading-8 text-blue text-center font-opensans mb-20"
            >
              {dataType === 1
                ? data.description
                : textResolver(data, "description")}
            </Text>
          ) : (
            ""
          )}
        </div>
        <div>
          {data?.items?.map((item, i, a) => {
            return (
              <div
                key={i}
                className={`lg:grid lg:grid-cols-2 lg:gap-24 xl:gap-28 items-center ${
                  i !== a.length - 1 ? "mb-20 lg:mb-24" : "mb-0"
                }`}
              >
                <div
                  className={` ${
                    order === "textFirst"
                      ? i % 2 === 0
                        ? "order-last"
                        : ""
                      : i % 2 !== 0
                      ? "order-last"
                      : ""
                  }`}
                >
                  <OptimizedImage
                    src={
                      dataType === 1 ? item.image : textResolver(item, "image")
                    }
                    alt="banner"
                    className={`hidden lg:block h-auto ${imageWidth}`}
                  />
                </div>
                <div className="">
                  {item.mainData.map((mainItem, i) => {
                    return (
                      <div key={i} className="pb-9">
                        <Text
                          as="h3"
                          highlightedText={
                            dataType === 1
                              ? mainItem.highlightWords
                              : textResolver(mainItem, "highlightWords")
                          }
                          highlightedColor={mainItem.highlightedColor}
                          className="text-24px leading-[34px] text-blue text-left font-primary font-medium pb-4 border-b border-gray-200 mb-4"
                        >
                          {dataType === 1
                            ? mainItem.title
                            : textResolver(mainItem, "title")}
                        </Text>
                        <Text
                          as="div"
                          //   highlightedText={
                          //     dataType === 1
                          //       ? mainItem.highlightWords
                          //       : textResolver(mainItem, "highlightWords")
                          //   }
                          //   highlightedColor={mainItem.highlightedColor}
                          className="text-base leading-8 text-blue text-left font-opensans"
                        >
                          {dataType === 1
                            ? mainItem.description
                            : textResolver(mainItem, "description")}
                        </Text>
                        {mainItem.list && (
                          <ul className="grid 0px:grid-cols-1 sm:grid-cols-2 list-disc pt-6 pl-5">
                            {mainItem.list?.map((listItem, i) => {
                              return (
                                <Text
                                  key={i}
                                  as="li"
                                  className="text-base leading-8 text-blue text-left font-opensans"
                                >
                                  {dataType === 1
                                    ? listItem
                                    : textResolver(listItem, "text")}
                                  {/* {listItem} */}
                                </Text>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BoxContainer>
  );
}
export default TwoColGridWithOrder;
