import React, { useState } from "react";
import chevronUp from "../../assets/arrow-up-orange.svg";
import chevronDown from "../../assets/arrow-down-blue.svg";
import Text from "../Text";

function AccordionSimple({ heading, description }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="w-full max-w-5xl mx-auto mb-6">
      <div
        className="xs:pl-[20px] xs:pr-[20px] lg:pl-[42px] lg:pr-[20px]"
        onClick={() => setIsActive((active) => !active)}
      >
        <div
          className={`flex justify-between items-center cursor-pointer pb-4 border-b ${
            isActive ? "border-gray-400" : "border-gray-200"
          }`}
        >
          <Text
            as="h3"
            className={`text-basexl leading-8 font-secondary font-medium my-auto mr-4 ${
              isActive ? "text-yellow" : "text-blue"
            }`}
          >
            {heading}
          </Text>
          <img
            src={isActive ? chevronUp : chevronDown}
            alt="open"
            className="w-8 h-auto basis-8"
          />
        </div>
      </div>
      {isActive && (
        <div className="py-6 px-5 lg:px-[42px]">
          <Text as="div" className="text-lg leading-8 text-blue font-opensans">
            {description}
          </Text>
        </div>
      )}
    </div>
  );
}

export default AccordionSimple;
